<template>
  <div class="salesTransactionList">
    <LoadingPlaceholder v-if="loading" />
    
    <template v-else>
      <Grid
        :ref="kgm_ref"
        :style="{height: 'auto'}"
        :data-items="kgm_computedGridItems(singleTransactions)"
        :columns="kgm_responsiveColumns()"
        :filterable="true"
        :filter="kgm_filter"
        :pageable="kgm_pagable"
        :page-size="kgm_take"
        :skip="kgm_skip"
        :take="kgm_take"
        :total="kgm_allGridItems(singleTransactions)"
        :sortable="{
          allowUnsort: kgm_allowUnsort,
          mode: kgm_sortMode
        }"
        :sort="kgm_sort"
        selected-field="selected"
        @rowclick="kgm_gridOnRowClick"
        @filterchange="kgm_gridFilterChange"
        @pagechange="kgm_gridPageChange"
        @sortchange="kgm_gridSortChange"
      >
        <template
          slot="borderTemplate"
          slot-scope="{props}"
        >
          <td :style="`border-left:4px solid ${ getStatusColor(props.dataItem.status) }`" />
        </template>
        <template
          slot="dateCell"
          slot-scope="{props}"
        >
          <td>
            {{ dateTime_dateTimeSeconds(kgm_getNestedValue(props.field, props.dataItem)) }}
          </td>
        </template>
        <template
          slot="cardHolderReceiptLink"
          slot-scope="{props}"
        >
          <td style="text-align: center; vertical-align: middle;">
            <a
              v-if="props.dataItem.cardHolderReceipt"
              :href="props.dataItem.cardHolderReceipt"
              target="_blank"
            >
              <font-awesome-icon
                size="lg"
                icon="receipt"
              />
            </a>
          </td>
        </template>
        <template
          slot="merchantReceiptLink"
          slot-scope="{props}"
        >
          <td style="text-align: center; vertical-align: middle;">
            <a
              v-if="props.dataItem.merchantReceipt"
              :href="props.dataItem.merchantReceipt"
              target="_blank"
            >
              <font-awesome-icon
                size="lg"
                icon="receipt"
              />
            </a>
          </td>
        </template>
        <template
          slot="typeCell"
          slot-scope="{props}"
        >
          <td class="capitalize">
            {{ props.dataItem.type }}
          </td>
        </template>
        <div
          slot="timeFilter"
          slot-scope="{props, methods}"
          class="input-group"
        >
          <input
            ref="timeFilterValue"
            :value="props.value"
            type="text"
            class="form-control mr-2 rounded-right"
            placeholder="please enter a date"
            @input="(event) => {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}"
          >
          <div
            v-if="props.value"
            class="input-group-append"
          >
            <button
              class="btn btn-light border rounded"
              @click="kgm_resetInput('timeFilterValue', (event) => {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})"
            >
              <font-awesome-icon
                class="gray"
                icon="times"
              />
            </button>
          </div>
        </div>
        <div
          slot="statusFilter"
          slot-scope="{props, methods}"
          class="input-group"
        >
          <input
            ref="statusFilterValue"
            :value="props.value"
            type="text"
            class="form-control mr-2 rounded-right"
            placeholder="please enter an status"
            @input="(event) => {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}"
          >
          <div
            v-if="props.value"
            class="input-group-append"
          >
            <button
              class="btn btn-light border rounded"
              @click="kgm_resetInput('statusFilterValue', (event) => {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})"
            >
              <font-awesome-icon
                class="gray"
                icon="times"
              />
            </button>
          </div>
        </div>
        <div
          slot="orderIdFilter"
          slot-scope="{props, methods}"
          class="input-group"
        >
          <input
            ref="orderIdFilterValue"
            :value="props.value"
            type="text"
            class="form-control mr-2 rounded-right"
            placeholder="please enter an ID"
            @input="(event) => {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}"
          >
          <div
            v-if="props.value"
            class="input-group-append"
          >
            <button
              class="btn btn-light border rounded"
              @click="kgm_resetInput('orderIdFilterValue', (event) => {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})"
            >
              <font-awesome-icon
                class="gray"
                icon="times"
              />
            </button>
          </div>
        </div>
        <div
          slot="typeFilter"
          slot-scope="{props, methods}"
          class="input-group"
        >
          <input
            ref="typeFilterValue"
            :value="props.value"
            type="text"
            class="form-control mr-2 rounded-right"
            placeholder="please enter a type"
            @input="(event) => {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}"
          >
          <div
            v-if="props.value"
            class="input-group-append"
          >
            <button
              class="btn btn-light border rounded"
              @click="kgm_resetInput('typeFilterValue', (event) => {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})"
            >
              <font-awesome-icon
                class="gray"
                icon="times"
              />
            </button>
          </div>
        </div>
        <div
          slot="amountFilter"
          slot-scope="{props, methods}"
          class="input-group"
        >
          <input
            ref="amountFilterValue"
            :value="props.value"
            type="text"
            class="form-control mr-2 rounded-right"
            placeholder="please enter a amount"
            @input="(event) => {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}"
          >
          <div
            v-if="props.value"
            class="input-group-append"
          >
            <button
              class="btn btn-light border rounded"
              @click="kgm_resetInput('amountFilterrValue', (event) => {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})"
            >
              <font-awesome-icon
                class="gray"
                icon="times"
              />
            </button>
          </div>
        </div>
        <div
          slot="cardsOrderedFilter"
          slot-scope="{props, methods}"
          class="input-group"
        >
          <input
            ref="cardsOrderedFilterValue"
            :value="props.value"
            type="text"
            class="form-control mr-2 rounded-right"
            placeholder="please enter a value"
            @input="(event) => {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}"
          >
          <div
            v-if="props.value"
            class="input-group-append"
          >
            <button
              class="btn btn-light border rounded"
              @click="kgm_resetInput('cardsOrderedFilter', (event) => {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})"
            >
              <font-awesome-icon
                class="gray"
                icon="times"
              />
            </button>
          </div>
        </div>
        <div
          slot="cardsPrintedFilter"
          slot-scope="{props, methods}"
          class="input-group"
        >
          <input
            ref="cardsPrintedFilterValue"
            :value="props.value"
            type="text"
            class="form-control mr-2 rounded-right"
            placeholder="please enter a value"
            @input="(event) => {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}"
          >
          <div
            v-if="props.value"
            class="input-group-append"
          >
            <button
              class="btn btn-light border rounded"
              @click="kgm_resetInput('cardsPrintedFilterValue', (event) => {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})"
            >
              <font-awesome-icon
                class="gray"
                icon="times"
              />
            </button>
          </div>
        </div>
        <div
          slot="cardsProducedFilter"
          slot-scope="{props, methods}"
          class="input-group"
        >
          <input
            ref="cardsProducedFilterValue"
            :value="props.value"
            type="text"
            class="form-control mr-2 rounded-right"
            placeholder="please enter a value"
            @input="(event) => {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}"
          >
          <div
            v-if="props.value"
            class="input-group-append"
          >
            <button
              class="btn btn-light border rounded"
              @click="kgm_resetInput('cardsProducedFilterValue', (event) => {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})"
            >
              <font-awesome-icon
                class="gray"
                icon="times"
              />
            </button>
          </div>
        </div>
        <template
          slot="optionsTemplate"
          slot-scope="{props}"
        >
          <td :class="props.className">
            <div class="btn-group">
              <button
                class="btn btn-primary btn-sm"
                @click="openTransactionModal(props.dataItem.id)"
              >
                <span>{{ $t('terminalForm.transactions') }}</span>
              </button>
            </div>
          </td>
        </template>
      </Grid>
    </template>
    <SweetModal
      ref="transactions"
      :title="$t('terminalForm.salesTransactions')"
      blocking
      class="overflowHidden"
    >
      <template v-if="selectedTransactions">
        <div
          style="overflow: auto; max-height: 450px;"
        >
          <table class="table">
            <thead>
              <tr>
                <th scope="col">
                  {{ $t('date') }}
                </th>
                <th scope="col">
                  {{ 'Status' }}
                </th>
                <th scope="col">
                  {{ 'Order Item ID' }}
                </th>
                <th scope="col">
                  {{ 'Order Item Amount' }}
                </th>
                <th scope="col">
                  {{ $t('terminalForm.cardsOrdered') }}
                </th>
                <th scope="col">
                  {{ $t('terminalForm.cardsPrinted') }}
                </th>
                <th scope="col">
                  {{ $t('terminalForm.cardsProduced') }}
                </th>
                <th
                  scope="col"
                  style="white-space: pre-line"
                >
                  {{ 'Media ID /\n\r Permission Number' }}
                </th>
                <th scope="col">
                  {{ $t('terminalForm.ticket') }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item, index) in selectedTransactions"
                :key="`id-listItem-${ index }`"
              >
                <td>
                  {{ dateTime_dateTimeSeconds(item.time) }}
                </td>
                <td>
                  {{ item.status }}
                </td>
                <td>
                  {{ item.orderItemId }}
                </td>
                <td>
                  {{ item.orderItemAmount }}
                </td>
                <td>
                  {{ item.cardsOrdered }}
                </td>
                <td>
                  {{ item.cardsPrinted }}
                </td>
                <td>
                  {{ item.cardsProduced }}
                </td>
                <td style="white-space: pre-line">
                  {{ item.mediaIdAndPermissionNumber }}
                </td>
                <td style="text-align: center; vertical-align: middle;">
                  <a
                    v-if="item.card"
                    :href="item.card"
                    target="_blank"
                  >
                    <font-awesome-icon
                      size="lg"
                      icon="ticket-alt"
                    />
                  </a>
                </td>
                <td v-if="item.errorMessage">
                  <div class="btn-group">
                    <button
                      class="btn btn-primary btn-sm"
                      @click="openErrorMessageModal(item)"
                    >
                      <span>{{ 'Error Message' }}</span>
                    </button>
                    <button
                      class="btn btn-primary btn-sm"
                      @click="copyErrorMessage(item)"
                    >
                      <span>{{ 'Copy Message' }}</span>
                    </button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </template>
      <button
        slot="button"
        class="btn btn-secondary float-right mb-3"
        @click="$refs.transactions.close()"
      >
        <font-awesome-icon
          class="mr-2"
          icon="times"
        />{{ $t('cancel') }}
      </button>
      <div class="clearfix" />
    </SweetModal>
  </div>
</template>

<script>
import { kendoGridMixin } from '@/mixins/kendoGridMixin.js';
import { dateTimeMixin } from '@/mixins/dateTimeMixin.js';
import { SweetModal } from 'sweet-modal-vue';

export default {
  name: "SalesTransactionList",
  components: {
    SweetModal
  },
  mixins: [
    kendoGridMixin,
    dateTimeMixin
  ],
  props: {
    installationId: {
      type: String,
      required: true
    },
    date: {
      type: Date,
      required: true
    }
  },
  data () {
    return {
      loading: true,
      groupedTransactions: null,
      singleTransactions: null,
      selectedTransactions: null,
      kgm_filter: {
        logic: "and",
        filters: [
          {
            field: 'key',
            operator: 'contains',
            value: ''
          }
        ]
      },
      kgm_sort: [
        {
          "field": "key",
          "dir": "asc"
        }
      ],
      kgm_columns: [
        {
          cell: 'borderTemplate',
          filterable: false,
          width: '4px'
        },
        {
          field: 'time',
          filterable: true,
          filter: 'text',
          title: this.$t('date'),
          filterCell: 'timeFilter',
          cell: 'dateCell'
        },
        {
          field: 'type',
          filterable: true,
          filter: 'text',
          title: 'Type',
          filterCell: 'typeFilter',
          cell: 'typeCell'
        },
        {
          field: 'status',
          filterable: true,
          filter: 'text',
          title: 'Status',
          filterCell: 'statusFilter'
        },
        {
          field: 'orderId',
          filterable: true,
          filter: 'text',
          title: 'Order Id',
          filterCell: 'orderIdFilter',
          hideOn: ['mdDown']
        },
        {
          field: 'amount',
          filterable: true,
          filter: 'text',
          title: this.$t('terminalForm.amount'),
          filterCell: 'amountFilter',
          hideOn: ['lgDown']
        },
        {
          field: 'cardHolderReceipt',
          filterable: false,
          filter: 'text',
          title: this.$t('terminalForm.cardHolderReceipt'),
          cell: 'cardHolderReceiptLink',
        },
        {
          field: 'merchantReceipt',
          filterable: false,
          filter: 'text',
          title: this.$t('terminalForm.merchantReceipt'), 
          cell: 'merchantReceiptLink',
          hideOn: ['mdDown']
        },
        {
          field: 'cardsOrdered',
          filterable: true,
          filter: 'text',
          title: this.$t('terminalForm.cardsOrdered'),
          filterCell: 'cardsOrderedFilter',
          hideOn: ['mdDown']
        },
        {
          field: 'cardsPrinted',
          filterable: true,
          filter: 'text',
          title: this.$t('terminalForm.cardsPrinted'),
          filterCell: 'cardsPrintedFilter',
          hideOn: ['mdDown']
        },
        {
          field: 'cardsProduced',
          filterable: true,
          filter: 'text',
          title: this.$t('terminalForm.cardsProduced'),
          filterCell: 'cardsProducedFilter',
          hideOn: ['xsDown']
        },
        {
          filterable: false,
          title: 'Options',
          cell: 'optionsTemplate',
        }
      ]
    }
  },
  watch: {
    date () {
      this.getSalesTransactions();
    }
  },
  created () {
    this.kgm_take = 50;
    this.getSalesTransactions();
  },
  methods: {
    openTransactionModal (id) {
      var transaction = this.groupedTransactions.find(x => x[0] == id);
      this.selectedTransactions = [];
      transaction[1].forEach(e => {
        var transformObj = this.transformObj(e);
        if(e._source.audit.id.includes('pickup.card.print.succeeded')) {
          transformObj.cardsPrinted = 1;
        }

        if(e._source.audit.id.includes('pickup.orderitem.produce.succeeded')) {
          transformObj.cardsProduced = 1;
        }
        
        if(e._source.audit.id.includes('pickup.orderitem.produce.failed')) {
          transformObj.cardsProduced = 0;
          transformObj.cardsPrinted = 0;
        }

        this.selectedTransactions.push(transformObj);
      });
      this.$refs.transactions.open();
    },
    openErrorMessageModal (item) {
      alert(item.errorMessage);
    },
    copyErrorMessage (item) {
      navigator.clipboard.writeText(item.errorMessage);
      this.$snotify.success("The Error message has been copied to your clipboard.");
    },
    stopLoading () {
      this.$emit("stopLoading", this.dateTime_dateTime(this.moment()));
    },
    splitStatus (status) {
      var delimiter = '.',
      start = 2,
      tokens = status.split(delimiter),
      result = [tokens.slice(0, start), tokens.slice(start)].map(function (item) {
        return item.join(delimiter);
      });
      return result;
    },
    getStatusColor (status) {
      if(status.includes("succeeded")){
        return '#4BB543';
      }
      if(status.includes("failed")) {
        return '#FA113D';
      }
      return null;
    },
    getMerchantReceipt (url) {
      return url.replace('cardholder', 'merchant');
    },
    transformObj (transaction, printedTransaction) {
      var stati = this.splitStatus(transaction._source.audit.id);
      if(stati[1].includes('pickup')) {
        stati[0] = 'pickup.skiticket';
      }
      
      var obj = {
        id: transaction._source.session.id,
        status: stati[1],
        type: stati[0].replace(".", " "),
        time: transaction._source.time,
        amount: (transaction._source.order_item && transaction._source.order_item.ticket_confirmation && transaction._source.order_item.ticket_confirmation.order) ? transaction._source.order_item.ticket_confirmation.order.amount : transaction._source.vending ? transaction._source.vending.balance : null,
        cardHolderReceipt: (printedTransaction && printedTransaction._source.receipt) ? printedTransaction._source.receipt.png : (transaction._source.payment && transaction._source.payment.receipt && transaction._source.payment.receipt.cardholder) ? transaction._source.payment.receipt.cardholder.png : null,
        merchantReceipt: transaction._source.payment ? transaction._source.payment.receipt.merchant.png : null,
        cardsOrdered: transaction._source.card_stats ? transaction._source.card_stats.count_ordered : transaction._source.card ? transaction._source.card.count_ordered : transaction._source.order ? transaction._source.order.ordered_items : null,
        cardsPrinted: transaction._source.card_stats ? transaction._source.card_stats.count_printed : transaction._source.card ? transaction._source.card.count_printed : transaction._source.order ? transaction._source.order.count_printed : null,
        cardsProduced: transaction._source.card_stats ? transaction._source.card_stats.count_produced : transaction._source.card ? transaction._source.card.count_produced : transaction._source.order ? transaction._source.order.post_produced_items : null,
        card: transaction._source.item ? transaction._source.item.image : null,
        errorMessage: transaction._source.error_message,
        orderId: (transaction._source.order_item && transaction._source.order_item.ticket_confirmation && transaction._source.order_item.ticket_confirmation.order) ? transaction._source.order_item.ticket_confirmation.order.id : transaction._source.order ? transaction._source.order.id : null,
        orderItemId: (transaction._source.order_item && transaction._source.order_item.id) ? transaction._source.order_item.id : (transaction._source.order_item && transaction._source.order_item.model_data) ? transaction._source.order_item.model_data.order_item_id : null,
        orderItemAmount: transaction._source.order_item ? transaction._source.order_item.amount : null, 
      };
      
      if(!obj.merchantReceipt && obj.cardHolderReceipt) {
        obj.merchantReceipt = this.getMerchantReceipt(obj.cardHolderReceipt);
      }
      
      if(transaction._source.order_item && transaction._source.order_item.produced_order_item) {
        var mediaId = transaction._source.order_item.produced_order_item.mediaId;
        var permissionNumber = transaction._source.order_item.produced_order_item.contractorSystemInfo ? transaction._source.order_item.produced_order_item.contractorSystemInfo.permissionNumber : null;
        obj.mediaIdAndPermissionNumber = mediaId + ' /\n\r' + permissionNumber;
      }
      

      return obj;
    },
    getVendingBalance () {

    },
    getSingleTransactions (t) {
      var transaction = null;
      var printedTransaction = null;
      transaction = t.find(x => x._source.audit.id.includes(".payment.commit.succeeded"));

      if(transaction == null) {
        transaction = t.find(x => x._source.audit.id.includes(".payment.create.canceled"));
      }

      if(transaction == null) {
        transaction = t[0];
      }

      if(printedTransaction == null) {
        printedTransaction = t.find(x => x._source.audit.id.includes(".receipt.print.succeeded")); 
      }

      if(printedTransaction == null) {
        printedTransaction = t.find(x => x._source.audit.id.includes(".receipt.print.failed")); 
      }

      if(transaction._source.audit.id.includes('payment.cancel.succeeded')) {
        var produceFailed =  t.find(x => x._source.audit.id.includes(".produce.failed"));
        if(produceFailed) {
          transaction._source.audit.id = produceFailed._source.audit.id;
        }
      }
      
      var transformed = this.transformObj(transaction, printedTransaction);
      if(transformed.status.includes('pickup.orderitem')) {
        transformed.cardsProduced = t.filter(x => x._source.audit.id.includes('pickup.orderitem.produce.succeeded')).length;
        transformed.cardsPrinted = t.filter(x => x._source.audit.id.includes('pickup.card.print.succeeded')).length;
        var amountObj = t.find(x => x._source.order_item);
        if(amountObj) {
          transformed.amount = amountObj._source.order_item.amount;
        }
      }

      return transformed;
    },
    //api calls
    getSalesTransactions () {
      this.loading = true;
      this.axios.post(`/Kibana/GetSalesTransactions?installationId=${ this.installationId }`, this.date)
      .then((response) => {
        if (response == null) {
          return;
        }
        if (response.data == null) {
          return;
        }
        var hits = response.data.hits.hits;
        if(hits) {
          var vendingBalance = hits.find(x => x._source.audit.id == "vending.balance.completed");
          if(vendingBalance) {
            var url = vendingBalance._source.balance.receipt.merchant.png;
            this.$emit('setBalanceUrl', url);
          }
          else {
            this.$emit('setBalanceUrl', null);
          }

          var hitsWithSession = hits.filter(x => x._source.session);
          let grouped = hitsWithSession.reduce((h, obj) => Object.assign(h, { [obj._source.session.id]:( h[obj._source.session.id] || [] ).concat(obj) }), {})
          this.groupedTransactions = Object.entries(grouped);
          this.singleTransactions = [];
          this.groupedTransactions.forEach(e => {
            this.singleTransactions.push(this.getSingleTransactions(e[1]));
          });
        }
      })
      .finally(() => {
        this.loading = false;
        this.stopLoading();
      });
    }
  }
}
</script>
<style>
.salesTransactionList .k-grid table tr:hover td {
  background :rgb(219, 219, 219) !important;
  cursor: pointer !important;
}
.salesTransactionList .k-grid  .k-grid-header  .k-header {
    white-space: normal;
}

.capitalize {
  text-transform: capitalize;
}
</style>